import './styles/Style.css';
import SidebarDisplay from './components/SidebarDisplay';
import MainContentDisplay from './components/MainContentDisplay';

function App() {
  return (
    <div className="App" id="page">
      <SidebarDisplay/>
      <MainContentDisplay/>
    </div>
  );
}

export default App;
