import React from "react";
import { CheckCircle, User, Sun,GitHub, Linkedin, List } from 'react-feather';
import resumePDF from "./Resume.pdf";

const SidebarDisplay = (props) => {
  //Start new content
  const openPDF = () => {
    const pdfWindow = window.open("test");
    const title     = "Resume";
    const URI       = "Resume";
    const html      = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${resumePDF} type="application/pdf">
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };
  return (
    <div id="sidebar">
        <div className="logo">
            <span className="logo">IF</span>
        </div>
        <div className="portfolio">
            <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/WeatherApp/"><Sun color="#F2C4CE" size={30} className="iconLink"></Sun></a><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/WeatherApp/"><span className="linkText">Weather  </span></a></span>
            <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/CVMaker/"><User color="#F2C4CE" size={30} className="iconLink"/></a><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/CVMaker/"><span className="linkText">CV Maker </span></a></span>
            <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/ToDo/"><CheckCircle color="#F2C4CE" size={30} className="iconLink"/></a><a target="_blank" rel="noreferrer noopener" href="https://issyfin.github.io/ToDo/"><span className="linkText">Tasks </span></a></span>
        </div>
        <div className="portfolio">
        <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href="https://github.com/IssyFin"><GitHub color="#F2C4CE" size={30} className="iconLink"/></a><a target="_blank" rel="noreferrer noopener" href="https://github.com/IssyFin"><span className="linkText">GitHub </span></a></span>
        <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/isabelle-finley"><Linkedin color="#F2C4CE" size={30} className="iconLink"/></a><a className="linkText" target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/in/isabelle-finley"><span className="linkText">LinkedIn </span></a></span>
        {/* <span className="linkRow"><a target="_blank" rel="noreferrer noopener" href={resumePDF}><List color="#F2C4CE" size={30} className="iconLink"/></a><span className="linkText">Resume</span></span> */}
        <span className="linkRow" onClick={openPDF}><List color="#F2C4CE" size={30} className="iconLink"/><span className="linkText" onClick={openPDF}>Resume</span></span>
        </div>
    </div>
  );
};

export default SidebarDisplay;