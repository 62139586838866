import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCagkE7CsvrWnoKSWwnvBs2Zq76yD0Crak",
  authDomain: "ifinley.firebaseapp.com",
  projectId: "ifinley",
  storageBucket: "ifinley.appspot.com",
  messagingSenderId: "1072440075116",
  appId: "1:1072440075116:web:82dab7c61a376249dec949"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);