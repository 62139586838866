import React from "react";

const MainContentDisplay = (props) => {
  return (
    <div id="main">
        <div id="title">
            Isabelle Finley
        </div>
        <div id="topCard">
            <div className="cardImage"><img alt="profilePic" src={require('../images/profile.jpg')}/></div> 
            <div className="cardText">
            My name is Isabelle Finley. I started my career in electrical engineering but have recently entered the beautiful world of web development. I’ve been interested in coding for nearly my whole life and in the past year have truly come to appreciate it. I started learning through The Odin Project and now have the skills and background to support my future career.
            </div>
        </div>
        <div>
        I’ve earned degrees in electrical engineering and management and have a minor in mathematics. Through my education I developed patterns of thinking and problem solving that provide a unique lens for web development. Working as an engineer, I communicated with stakeholders both informally and in technical writing. I implemented Agile methodologies in product development and led efforts for obsolescence planning. I firmly believe that these skills not only translate to the software world but may also offer new perspectives for my future team. All this said, there is much I do not yet know, and I am therefore committed to being a life-long learner.
        </div>
        <div id="bottomCard">
            <div className="cardText">
            When I'm not learning or working, my hobbies keep me busy. You can find me exploring my home city of Pittsburgh, reading a fantasy novel, finding a new trail, knitting a pair of mittens, or playing videogames with my friends and siblings.
             </div>
            <img className="cardImage" alt="funPic" src={require('../images/profile2.jpg')}/>
        </div>
        <div className="skillBoxes">
          <div className="skillsBox">
              <span className="skillsTitle">Technical Competencies</span>
              <ul>
                <li>Front-End Development</li>
                <li>ReactJS</li>
                <li>Javascript</li>
                <li>CSS</li>
                <li>HTML</li>
                <li>SQL</li>
                <li>RESTful APIs</li>
                <li>Cloud Hosting</li>
                <li>UX-UI Design</li>
                <li>Responsive Design</li>
                <li>Testing</li>
              </ul>
          </div>
          <div className="skillsBox">
              <span className="skillsTitle">Other Skills</span>
              <ul>
                <li>Project Management</li>
                <li>Stakeholder Communication</li>
                <li>Leadership</li>
                <li>Technical Writing</li>
                <li>Communication</li>
                <li>Establishing Workflows</li>
                <li>Attention to Detail</li>
                <li>Technical Design Review</li>
                <li>Problem Solving</li>
                <li>Time Management</li>
                <li>Focus on Client Needs</li>
              </ul>
          </div>
        </div>
    </div>
  );
};

export default MainContentDisplay;